
import { defineComponent, ref } from "vue";
import { ErrorMessage, Field, Form } from "vee-validate";
import * as Yup from "yup";

export default defineComponent({
  name: "new-address-modal",
  components: {
    ErrorMessage,
    Field,
    Form
  },
  data() {
    return {
      name: ""
    };
  },
  setup() {
    const submitButtonRef = ref<null | HTMLButtonElement>(null);

    const validationSchema = Yup.object().shape({
      name: Yup.string().required().label("Product category name")
    });

    return {
      validationSchema,
      submitButtonRef
    };
  }
});
