
import { defineComponent, onMounted } from "vue";
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import MainProductTable from "@/components/admin/MainProductTable.vue";
import AddProductCategory from "@/components/modals/AddProductCategory.vue";

export default defineComponent({
  name: "individual-client-report-claims",
  components: {
    MainProductTable,
    AddProductCategory
  },
  setup() {
    onMounted(() => {
      setCurrentPageBreadcrumbs("Main Category", ["Insurance Products"]);
    });
  },
  created() {
    //Set page title
    document.title =
      "Insurance Products: Main Category | " + process.env.VUE_APP_TITLE;
  }
});
