import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "row gy-5 gx-xl-8" }
const _hoisted_2 = { class: "col-xxl-8" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_MainProductTable = _resolveComponent("MainProductTable")!
  const _component_AddProductCategory = _resolveComponent("AddProductCategory")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, [
        _createVNode(_component_MainProductTable, { "widget-classes": "card-xxl-stretch mb-5 mb-xl-8" })
      ])
    ]),
    _createVNode(_component_AddProductCategory)
  ], 64))
}