
import { defineComponent, onMounted } from "vue";
import ApiService from "@/core/services/ApiService";
import JwtService from "@/core/services/JwtService";
import variables from "@/router/api";
import ResusableFunctions from "@/composables/ReusableFunctions";

export default defineComponent({
  name: "admin-main-product-table",
  components: {},
  props: {
    widgetClasses: String,
  },
  data() {
    return {
      packages: [
        {
          name: "",
          businessClass: "",
          businessType: "",
        },
      ],
    };
  },
  setup() {
    const { displayDatatable } = ResusableFunctions();

    onMounted(() => {
      displayDatatable(["basicExample"]);
    });
  },
  created() {
    this.getMainProducts();
  },
  methods: {
    getMainProducts() {
      //Fetch the list of the main insurance products
      if (JwtService.getToken()) {
        ApiService.setHeader();
        ApiService.get(variables.SUPERADMIN_PRODUCTS_ROUTE)
          .then(({ data }) => {
            //Assign data to form fields
            this.packages = data.data;
          })
          .catch(function(error) {
            variables.toastAlert(error.response.data.error, "error");
          });
      }
    },
  },
});
